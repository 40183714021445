<template>
  <div class="TalentRecruitmentDetail">
    <div class="head f-c-b">
      <div class="DetailInfo">
        <div>
          <span class="postName">{{ detailInfo.position }}</span>
          <span class="salary">{{ detailInfo.salary }}</span>
        </div>
        <div v-if="detailInfo.deleteFlag == ''" class="btn" @click="visible = true">投递简历</div>
        <div v-else class="btn" @click="addCancelForumResumeInto('','Y')">取消投递</div>
      </div>
      <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">
          <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
          <span> 当前位置： 首页</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/TalentRecruitmentIndex' }">人才招聘</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/TalentRecruitmentList' }">岗位招聘</el-breadcrumb-item>
        <el-breadcrumb-item>职位详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main f-c-b">
      <div class="skill">
        <div class="skill_title">岗位详情：</div>
        <div v-html="detailInfo.postContent" class="postContent"></div>
      </div>
      <div class="enterprise">
        <el-image :src="detailInfo.enterprise ? detailInfo.enterprise.eimage1 : ''"></el-image>
        <div class="f-c-c">
          <span class="enterprise_name">{{ enterprise ? enterprise.enterpriseName : '' }}</span>
          <div class="detailsBtn" @click="toEnterprise">
            详情
          </div>
        </div>
        <div class="blueText">公司地址：</div>
        <span class="info">{{ enterprise.enterpriseName ? enterprise.enterpriseName : '未填写' }}</span>
        <div class="blueText">联系人：</div>
        <span class="info">{{ enterprise.contact ? enterprise.contact : '未填写' }}</span>
        <div class="blueText">联系方式：</div>
        <span class="info">{{ enterprise.contactTelephone ? enterprise.contactTelephone : '未填写' }}</span>
      </div>
    </div>

    <Dialog title="选择投递的简历" width="690px" :visible="visible" @closeDialog="visible = false"
      class="Dialog">
      <div class="list f-cl-c">
                <div v-if="resumelist" class="resumelist f-cl-c">
                    <div v-for="(item, index) in resumelist" :key="index" class="resumeItem f-c-b" @click="Seleterresume(item.resumeId)">
                        <div class="infoBox">
                            <div class="flex">
                                <div>
                                    <span class="name">{{ item.name }}</span>
                                    <span class="contactValue"> · </span>
                                    <span class="contactValue">{{ item.contactValue }}</span>
                                </div>
                                <div :class="item.deleteFlag == 'S' ? 'deleteFlagS' : 'deleteFlagY'">
                                    {{ item.deleteFlag == "S" ? '未公开' : '公开中' }}
                                </div>
                            </div>

                            <div style="margin-top: 15px;margin-bottom: 15px;">
                                <span class="common">{{ item.workAge }}年</span>
                                <span class="common"> | </span>
                                <span class="common">{{ item.topDegree ?
                                    item.topDegreeVlaue : '未填写' }}</span>
                                <span class="common"> | </span>
                                <span class="common">{{ item.salary }}</span>
                            </div>
                            <div>
                                <img class="w-22 h-20 mr-10" src="@/assets/Community/jobIcon.png" alt="">
                                <span class="position">{{ item.position }}</span>
                            </div>
                        </div>
                        <img src="@/assets/Community/head.png" alt="">
                    </div>
                </div>
            </div>
    </Dialog>



  </div>
</template>

<script>
import { UrlDecodeIdType } from '@/mixins/UrlDecodeMixin';
import { ChineseCharactersInputMixin } from '@/mixins/ChineseCharactersInputMixin'
import { CommodityTypeClass } from "@/utils/InviteJob.js"
export default {
  name: 'TalentRecruitmentDetail',
  mixins: [UrlDecodeIdType, ChineseCharactersInputMixin],
  data() {
    return {
      disabledInput: true,
      detailInfo: {},
      enterprise: {},
      resumelist:[],
      visible:false
    }
  },
  created() {
    this.forumRecruitArticleDetails();
    this.forumResumeMyList();
  },
  methods: {
    //  职位招聘帖子列表详情接口
    forumRecruitArticleDetails() {
      let that = this;
      that.$http.forumRecruitArticleDetails({
        postUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        postId: that.queryId
      }).then(res => {
        if (res.code == 200) {
          that.detailInfo = res.data;
          that.enterprise = res.data.enterprise;
          that.detailInfo.postContent = that.detailInfo.postContent.replace(/\n/g, '<br/>');
        }
      })
    },
    toEnterprise() {
      this.$setStorage('EnterpriseInternalRecruitmentInfo', this.detailInfo)
      this.$router.push('/EnterpriseInternalRecruitment')
    },
    forumResumeMyList(){
      let that = this;
      that.$http.forumResumeMyList({
          userId: parseInt(that.$store.state.userId)
      }).then(res => {
          if (res.code == 200) {
              let records = res.data.records;
              records.forEach((item) => {
                  item.topDegreeVlaue = CommodityTypeClass.getDegreetype(item.topDegree);
              })
              that.resumelist = records;
          }
      })
    },
    Seleterresume(id){
      this.visible = false;
      let saveState = 'N';
      this.addCancelForumResumeInto(id,saveState)
    },
    addCancelForumResumeInto(resumeId,saveState){
      let that = this;
      let { postId, position,enterpriseId,enterpriseName } = that.detailInfo;
      that.$http.addCancelForumResumeInto({
        resumeId,
        userId: parseInt(that.$store.state.userId),
        enterpriseId,
        enterpriseName,
        recruitId: postId,
        recruitName: position,
        saveState
      }).then(res => {
        if (res.code == 200) {
          that.$common.message(res.msg, 'success');
          this.$router.go(-1);
        }
      })
    }
  },
}
</script>
<style lang='less' scoped>
.TalentRecruitmentDetail {
  .head {
    .DetailInfo {
      .postName {
        font-size: 36px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        color: #333333;
        margin-right: 70px;
      }

      .salary {
        font-size: 30px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        color: #127FF5;

      }

      .btn {
        margin-top: 30px;
        font-size: 16px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        color: #fff;
        text-align: center;
        width: 160px;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
      color: #666666 !important;
      font-weight: 400 !important;
      font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
      color: #0363FA !important;
      font-weight: 400 !important;
      font-size: 14px;
    }
  }

  .main {
    margin-top: 80px;

    .skill {
      width: 670px;
      height: 420px;
      background: #F8F8F8;
      border-radius: 10px;
      padding: 40px 50px;

      .skill_title {
        font-size: 18px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        color: #127FF5;
        margin-bottom: 20px;
      }

      .postContent {
        font-size: 16px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #333333;
      }
    }

    .enterprise {
      width: 310px;
      height: 450px;
      background: #F8F8F8;
      border-radius: 10px;
      padding: 30px 45px;

      .el-image {
        width: 190px;
        height: 146px;
        border-radius: 10px;
      }

      .enterprise_name {
        margin-top: 24px;
        font-size: 24px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        color: #333333;
        width: 260px;
        line-height: 30px;
      }

      .detailsBtn {
        text-align: center;
        font-size: 16px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #127FF5;
        width: 60px;
        height: 30px;
        line-height: 30px;
        background: #fff;
        border-radius: 5px;
        cursor: pointer;
      }

      .blueText {
        font-size: 18px;
        font-family: HarmonyOS Sans SC;
        font-weight: 500;
        color: #127FF5;
        line-height: 20px;
        margin-top: 25px;
        margin-bottom: 15px;
      }

      .info {
        font-size: 16px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        // margin-top: 15px;
      }
    }
  }
  .Dialog{
    .list {
        width: 100%;

        .resumelist {
            width: 80%;

            .resumeItem {
                margin-bottom: 30px;
                border-radius: 20px;
                border: 1px solid #C4C4C4;
                width: 500px;
                height: 96px;
                padding: 32px 50px;
                cursor: pointer;

                .infoBox {
                    .name {
                        font-size: 28px;
                        color: #333333;
                    }

                    .contactValue {
                        font-size: 20px;
                        color: #333333;
                    }

                    .deleteFlagS {
                        margin-left: 70px;
                        width: 80px;
                        height: 29px;
                        background: #FF3500;
                        opacity: 0.7;
                        border-radius: 5px;
                        color: #FFF;
                        line-height: 29px;
                        text-align: center;
                    }
                    .deleteFlagY {
                        margin-left: 70px;
                        width: 80px;
                        height: 29px;
                        background: #00BAFF;
                        opacity: 0.7;
                        border-radius: 5px;
                        color: #FFF;
                        line-height: 29px;
                        text-align: center;
                    }

                    .common {
                        color: #666666;
                        font-size: 16px;
                    }

                    .position {
                        font-size: 20px;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
        }
    }
  }
}
</style>